<template>
  <div class="upgrade-tips">
  
    <Header></Header>
    <div class="img-box">
      <img src="@/assets/images/menu/upgrade_ie.svg" />
    </div>
    <div class="title">检测到当前浏览器版本较低，存在兼容性问题。</div>
    <div class="title">请使用最新版本 {{ name }} 浏览器</div>
    <div v-if="!isWin10 && !isWX && this.$router.history._startLocation.match(/join/)" class="buttons">
      <!-- <div class="green-button">
        <el-button @click="hrefChrome">点击跳转到Chrome</el-button>
        <label>若已安装Chrome浏览器</label>
      </div> -->
      <div class="whilt-button">
        <el-button @click="loadChrome">前往下载Chrome</el-button>
      </div>
      <div class="chrome-tips">
        如已安装Chrome浏览器 ，请<p v-clipboard:copy="url" v-clipboard:success="onCopy">复制会议链接</p>在<br>Chrome浏览器里打开
      </div>
    </div>

    <div v-else-if="isWin10 && !isWX && this.$router.history._startLocation.match(/join/)" class="buttons">
      <div class="green-button">
        <el-button @click="hrefEdge">点击跳转到Edge</el-button>
      </div>

      <div class="chrome-tips"> 
       如果您想使用Chrome浏览器，<p @click="loadChrome">点击此处下载</p><br> 如已安装 ，请<p v-clipboard:copy="url" v-clipboard:success="onCopy">复制会议链接</p>在Chrome浏览器里打开
      </div>
    </div>

    <div class="weixin-warp" v-if="isWX">
      <div class="content">
        <img src="@/assets/images/menu/jiantou.svg" />
        <div>医百会议Web端暂不支持微信内置浏览器<br>请点击此处使用系统浏览器打开</div>
      </div>
    </div>
    <div class="downLoadWindow" v-if="!isWX" v-show="isShow">
      <img class='img_style' src="@/assets/images/down.png" alt="">
      <my-icon class="closeIcon" :iconName="'iconxitonglan_guanbi_hei'" :fontSize="16" @click="innerClose"></my-icon>
      <a class="ddl" href="https://cloud.100doc.com.cn/product/ybMeeting/dashboard" target="_blank">
        <div class="downLoadTitle">{{ $t('home.titleTips') }}</div>
        <div class="quickJoinTitle" :style="{'margin-left':$i18n.locale === 'en-US'?'88px':'150px'}">{{ $t('home.quickJoin') }}</div>
      </a>
    </div>

    <Footer></Footer>
  </div>

</template>
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer"
import { getBrowser } from "@/utils/tool"
import { CHROME_VERSON_MIN, EDGE_VERSON_MIN, SAFARI_VERSON_MIN } from "@/constant/index"


export default {
   components: {
    Header,
    Footer
  },
  data() {
    return {
      name:'Chrome',
      isWin10:false,
      isWX:false,
      isShow:true,
      url:window.location.origin + this.$router.history._startLocation
    }
  },
  methods: {
      innerClose() {
        this.isShow = false;
      },
    remSize() {
      let designSize = 4096; // 设计图尺寸
      let html = document.documentElement;
      let wW = html.clientWidth;// 窗口宽度
      if(wW>820) wW = 820
      let rem = wW * 100 / designSize; 
      document.documentElement.style.fontSize = rem + 'px';
    },
    hrefChrome() {
      // window.location.href = "%22googlechrome%22" + window.location.origin
      window.open('chrome://settings/content/sound')
    },
    hrefEdge() {
      window.location.href = "microsoft-edge:" + window.location.origin
    },
    loadChrome() {
      window.location.href = "https://www.google.cn/intl/zh-CN/chrome/"
    },
    onCopy(e) {
      const {
        text
      } = e
      let copyText = ''
      if (text.indexOf('https') > -1) { // 邀请信息
        copyText = this.$t('seeting.inviteInfo')
      } else {
        copyText = this.$t('meeting.meetID')
      }
      this.$totast(`复制成功`)
    },
    detectOS() {
      var sUserAgent = navigator.userAgent;
      if (sUserAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
        this.isWX =  true;
      }
      var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
      var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
      if (isMac) return "Mac";
      var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
      if (isUnix) return "Unix";
      var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
      
      var bIsAndroid = sUserAgent.toLowerCase().match(/android/i) == "android";
      if (isLinux) {
      if(bIsAndroid) return "Android";
      else return "Linux";
      }
      if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 ||
        sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
      }
      if(sUserAgent.indexOf("Windows NT 8")!=-1){
        return"win8";
      }
      if(sUserAgent.indexOf("Windows NT 10")!=-1){
        return"win10";
      }
      return "other";
    }
  },
  created() {
    const browser = getBrowser()
    // || (browser.safari && browser.safari >= SAFARI_VERSON_MIN
    if (browser.chrome && browser.chrome >= CHROME_VERSON_MIN
    ||(browser.edge && browser.edge >= EDGE_VERSON_MIN)
    ){
      document.documentElement.style.fontSize = '';
      this.$router.push({ name: "index" });
      return
    }

    this.remSize()
    // 窗口发生变化时调用
    window.onresize = () => {
      this.remSize()
    }
    console.error('detectOS',this.detectOS())
    let detectOS = this.detectOS()
    
    if(detectOS == 'win10'){
      this.isWin10 = true
      this.name = "Edge 或 Chrome"
    }else{
      this.isWin10 = false
      this.name = "Chrome"
    }
    if(this.isWX){
      this.name = "Edge 或 Chrome"
    }

    this.isMac = this.detectOS() == 'Mac'
  }
}
</script>


<style lang="scss">
  @mixin btn_style($bg,$color){
    width: 12rem;
    height: 2rem;
    padding:0 1rem;
    font-size: 0.7rem;
    border-radius: .2rem;
    background-color:$bg;
    border-color: #1ab370;
    color:$color;
    display: block;
    margin: auto;
  }
  .upgrade-tips{
    .right{
      display: none !important;
    }
    .footer{
      bottom: 0;
      position: fixed;
    }
    .img-box {
      display: flex;
      justify-content: center;
      padding-top: 3.2rem;
      img {
        width: 6.4rem;
        height: 6.4rem;
        display: block;
      }
    }
    
    .title {
      text-align: center;
      font-size: 1rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 1.5rem;
    }
    .chrome-tips{
      text-align: center;
      display: inline-block;
      font-size: 0.7rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 1rem;
      padding: 1rem 2rem;
      margin: 1.6rem auto 0;
      border: 1px solid #E5E5E5;
      border-radius: .4rem;
      p{
        display: initial;
        color: #1890FF;
        text-decoration:underline;
      }
    }
    .whilt-button {
      .el-button--default{
      @include btn_style(#fff,#1ab370);
        &:hover{
          background: rgba(26, 179, 112, 0.1);
        }
        &:focus{
          background: rgba(26, 179, 112, 0.2);
        }
      } 
    }
    .buttons{
      margin-top: 1.8rem;
      text-align: center;
      label{
        font-size: 0.7rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 1rem;
        text-align: center;
        display: block;
        margin-top: 0.8rem;
      }
    }
    .green-button {
      .el-button--default{
      @include btn_style(#1ab370, #fff);
        &:hover{
          background: #3BBF82;
        }
        &:focus{
          background: #0E8C5A;
        }
      }
    }
    .weixin-warp{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, 0.6);
      .content{
        padding-left: 140px;
        display: flex;
        img{
          width: 8rem;
        }
        div{
          margin-top: 4.8rem;
          font-size: 1.1rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 2.2rem;
        }
      }
    }


    .downLoadWindow {
      width: 16rem;
      height: 5rem;
      background: #ffffff;
      box-shadow: 0px .2rem .5rem 0px rgba(102, 102, 102, 0.1);
      border-radius: .4rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      position: absolute;
      right: .8rem;
      bottom: 3.2rem;
      .closeIcon {
        margin: .5rem 0 .6rem 14.7rem;
        cursor: pointer;
      }
      .ddl {
        width: 15rem;
        height: 5rem;
      }
      .img_style {
        width: 3rem;
        height: 3rem;
        position: absolute;
        margin: 1rem 1.2rem;
      }
      @mixin title_style($size,$weight,$color){
        position: absolute;
        font-size: $size;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: $weight;
        color: $color;
      }
      .downLoadTitle {
        @include title_style(16px,500,#333333);
        margin-left: 5.8rem;
        margin-top: -1.2rem;
      }
      .quickJoinTitle{
        @include title_style(14px,300,#FF4D4F);
        margin-top: 0.2rem;
      }
    }
    .version-container {
      padding-top: 1.2rem;
      color: #999999;
      flex: 1;
    }
  }

</style>
